import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout/Layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>

    <div>
      <h2>Error 404</h2>
      <p>Sorry, that page can't be found</p>
    </div>
  </Layout>
)

export default NotFoundPage
